/**
 * @author Evgeny Khovansky <fajesu@ya.ru>
 * @copyright 2019 Digital Fresh
 * @link https://www.d-fresh.ru/
 */
html, body,
div, span, .db, .dib,
applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead,
tr, th, td, .dtr, .dtc,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  box-sizing: border-box;
  float: none;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: top;
  text-decoration: none;
  text-transform: none;
  outline: none;

  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  color: #2e3a41;
  font-family: "Bitter", "Times New Roman", serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  word-spacing: 0;
}

ol, ul {
  list-style: none outside;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table, .dt {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: #2e3a41;
  text-decoration: none;
}

input, textarea, button, select {
  font-family: "Bitter", "Times New Roman", serif;;
  overflow: hidden;
  resize: none;
  outline: none;

  -moz-appearance: none;
  appearance: normal;

  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
  input:not([type="radio"]):not([type="checkbox"]),
  textarea,
  button,
  select {
    -webkit-appearance: none;
  }

  input[type="number"] { -moz-appearance: textfield; }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button { -webkit-appearance: none; display: none; }

  textarea {
    overflow: auto;
    white-space: pre-wrap;
  }
  button::-moz-focus-inner {
    border: none;
  }
  select::-ms-expand {
    display: none;
  }
