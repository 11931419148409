/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}


/* Line height */
/* =========== */
.lh10 { line-height: 1; }
.lh100p { line-height: 100%; }
.lh11 { line-height: 1.1; }
.lh110p { line-height: 110%; }
.lh12 { line-height: 1.2; }
.lh120p { line-height: 120%; }
.lh13 { line-height: 1.3; }
.lh130p { line-height: 130%; }
.lh14 { line-height: 1.4; }
.lh140p { line-height: 140%; }
.lh15 { line-height: 1.5; }
.lh150p { line-height: 150%; }
.lh16 { line-height: 1.6; }
.lh160p { line-height: 160%; }
.lh17 { line-height: 1.7; }
.lh170p { line-height: 170%; }
.lh18 { line-height: 1.8; }
.lh180p { line-height: 180%; }
.lh19 { line-height: 1.9; }
.lh190p { line-height: 190%; }
.lh20 { line-height: 2; }
.lh200p { line-height: 200%; }


/* Color */
/* ===== */
.c0, .c0_a, .c0_a *, .c0_h:hover, .c0_a_h:hover, .c0_a_h:hover * { color: #000; }
.c0_i, .c0_a_i, .c0_a_i *, .c0_h_i:hover, .c0_a_h_i:hover, .c0_a_h_i:hover * { color: #000 !important; }
.cw, .cw_a, .cw_a *, .cw_h:hover, .cw_a_h:hover, .cw_a_h:hover * { color: #fff; }
.cw_i, .cw_a_i, .cw_a_i *, .cw_h_i:hover, .cw_a_h_i:hover, .cw_a_h_i:hover * { color: #fff !important; }
.cc, .cc_h:hover { color: #ccc; }
.cc_i, .cc_h_i:hover { color: #ccc !important; }
.c90, .c90_h:hover { color: #909090; }
.c90_i, .c90_h_i:hover { color: #909090 !important; }
.c50, .c50_h:hover { color: #505050; }
.c50_i, .c50_h_i:hover { color: #505050 !important; }


/* Background */
/* ========== */
/* Background position */
.bgptl { background-position: top left; }
.bgptc { background-position: top center; }
.bgptr { background-position: top right; }
.bgpcl { background-position: center left; }
.bgpcc { background-position: center center; }
.bgpcr { background-position: center right; }
.bgpbl { background-position: bottom left; }
.bgpbc { background-position: bottom center; }
.bgpbr { background-position: bottom right; }

/* Background repeat */
.bgrr { background-repeat: repeat; }
.bgrnr { background-repeat: no-repeat; }
.bgrrx { background-repeat: repeat-x; }
.bgrry { background-repeat: repeat-y; }

/* Background color */
.bgc0, .bgc0_h:hover { background-color: #000; }
.bgc0_i, .bgc0_h_i:hover { background-color: #000 !important; }
.bgc0_25p, .bgc0_25p_h:hover { background-color: rgba(0,0,0,.25); }
.bgc0_25p_i, .bgc0_25p_h_i:hover { background-color: rgba(0,0,0,.25) !important; }
.bgc0_50p, .bgc0_50p_h:hover { background-color: rgba(0,0,0,.5); }
.bgc0_50p_i, .bgc0_50p_h_i:hover { background-color: rgba(0,0,0,.5) !important; }
.bgc0_75p, .bgc0_75p_h:hover { background-color: rgba(0,0,0,.75); }
.bgc0_75p_i, .bgc0_75p_h_i:hover { background-color: rgba(0,0,0,.75) !important; }
.bgcw, .bgcw_h:hover { background-color: #fff; }
.bgcw_i, .bgcw_h_i:hover { background-color: #fff !important; }
.bgcw_25p, .bgcw_25p_h:hover { background-color: rgba(255,255,255,.25); }
.bgcw_25p_i, .bgcw_25p_h_i:hover { background-color: rgba(255,255,255,.25) !important; }
.bgcw_50p, .bgcw_50p_h:hover { background-color: rgba(255,255,255,.5); }
.bgcw_50p_i, .bgcw_50p_h_i:hover { background-color: rgba(255,255,255,.5) !important; }
.bgcw_75p, .bgcw_75p_h:hover { background-color: rgba(255,255,255,.75); }
.bgcw_75p_i, .bgcw_75p_h_i:hover { background-color: rgba(255,255,255,.75) !important; }
.bgcf6, .bgcf6_h:hover { background-color: #f6f6f6; }
.bgcf6_i, .bgcf6_h_i:hover { background-color: #f6f6f6 !important; }
.bgcea, .bgcea_h:hover { background-color: #eaeaea; }
.bgcea_i, .bgcea_h_i:hover { background-color: #eaeaea !important; }
.bgcc, .bgcc_h:hover { background-color: #ccc; }
.bgcc_i, .bgcc_h_i:hover { background-color: #ccc !important; }
.bg90, .bg90_h:hover { background-color: #909090; }
.bg90_i, .bg90_h_i:hover { background-color: #909090 !important; }
.bg50, .bg50_h:hover { background-color: #505050; }
.bg50_i, .bg50_h_i:hover { background-color: #505050 !important; }


/* Border */
/* ====== */
/* Border color */
.brct, .brct_h:hover { border-color: transparent; }
.brct_i, .brct_h_i:hover { border-color: transparent !important; }
.brc0, .brc0_h:hover { border-color: #000; }
.brc0_i, .brc0_h_i:hover { border-color: #000 !important; }
.brcw, .brcw_h:hover { border-color: #fff; }
.brcw_i, .brcw_h_i:hover { border-color: #fff !important; }
.brcf6, .brcf6_h:hover { border-color: #f6f6f6; }
.brcf6_i, .brcf6_h_i:hover { border-color: #f6f6f6 !important; }
.brcea, .brcea_h:hover { border-color: #eaeaea; }
.brcea_i, .brcea_h_i:hover { border-color: #eaeaea !important; }
.brcc, .brcc_h:hover { border-color: #ccc; }
.brcc_i, .brcc_h_i:hover { border-color: #ccc !important; }
.brc90, .brc90_h:hover { border-color: #909090; }
.brc90_i, .brc90_h_i:hover { border-color: #909090 !important; }
.brc50, .brc50_h:hover { border-color: #505050; }
.brc50_i, .brc50_h_i:hover { border-color: #505050 !important; }

/* Border width */
.brw1 { border-width: 1px; }
.brtw1 { border-top-width: 1px; }
.brlw1 { border-left-width: 1px; }
.brrw1 { border-right-width: 1px; }
.brbw1 { border-bottom-width: 1px; }
.brw2 { border-width: 2px; }
.brtw2 { border-top-width: 2px; }
.brlw2 { border-left-width: 2px; }
.brrw2 { border-right-width: 2px; }
.brbw2 { border-bottom-width: 2px; }
.brw3 { border-width: 3px; }
.brtw3 { border-top-width: 3px; }
.brlw3 { border-left-width: 3px; }
.brrw3 { border-right-width: 3px; }
.brbw3 { border-bottom-width: 3px; }
.brw5 { border-width: 5px; }
.brtw5 { border-top-width: 5px; }
.brlw5 { border-left-width: 5px; }
.brrw5 { border-right-width: 5px; }
.brbw5 { border-bottom-width: 5px; }
.brw7 { border-width: 7px; }
.brtw7 { border-top-width: 7px; }
.brlw7 { border-left-width: 7px; }
.brrw7 { border-right-width: 7px; }
.brbw7 { border-bottom-width: 7px; }
.brw10 { border-width: 10px; }
.brtw10 { border-top-width: 10px; }
.brlw10 { border-left-width: 10px; }
.brrw10 { border-right-width: 10px; }
.brbw10 { border-bottom-width: 10px; }

/* Border style */
.brstn { border-style: none; }
.brsts { border-style: solid; }
.brstd { border-style: dashed; }

/* Border radius */
.brs2 { border-radius: 2px; }
.brst2 { border-radius: 2px 2px 0px 0px; }
.brsl2 { border-radius: 2px 0px 0px 2px; }
.brsr2 { border-radius: 0px 2px 2px 0px; }
.brsb2 { border-radius: 0px 0px 2px 2px; }
.brs3 { border-radius: 3px; }
.brst3 { border-radius: 3px 3px 0px 0px; }
.brsl3 { border-radius: 3px 0px 0px 3px; }
.brsr3 { border-radius: 0px 3px 3px 0px; }
.brsb3 { border-radius: 0px 0px 3px 3px; }
.brs4 { border-radius: 4px; }
.brst4 { border-radius: 4px 4px 0px 0px; }
.brsl4 { border-radius: 4px 0px 0px 4px; }
.brsr4 { border-radius: 0px 4px 4px 0px; }
.brsb4 { border-radius: 0px 0px 4px 4px; }
.brs5 { border-radius: 5px; }
.brst5 { border-radius: 5px 5px 0px 0px; }
.brsl5 { border-radius: 5px 0px 0px 5px; }
.brsr5 { border-radius: 0px 5px 5px 0px; }
.brsb5 { border-radius: 0px 0px 5px 5px; }
.brs7 { border-radius: 7px; }
.brst7 { border-radius: 7px 7px 0px 0px; }
.brsl7 { border-radius: 7px 0px 0px 7px; }
.brsr7 { border-radius: 0px 7px 7px 0px; }
.brsb7 { border-radius: 0px 0px 7px 7px; }
.brs10 { border-radius: 10px; }
.brst10 { border-radius: 10px 10px 0px 0px; }
.brsl10 { border-radius: 10px 0px 0px 10px; }
.brsr10 { border-radius: 0px 10px 10px 0px; }
.brsb10 { border-radius: 0px 0px 10px 10px; }
.brs13 { border-radius: 13px; }
.brst13 { border-radius: 13px 13px 0px 0px; }
.brsl13 { border-radius: 13px 0px 0px 13px; }
.brsr13 { border-radius: 0px 13px 13px 0px; }
.brsb13 { border-radius: 0px 0px 13px 13px; }
.brs15 { border-radius: 15px; }
.brst15 { border-radius: 15px 15px 0px 0px; }
.brsl15 { border-radius: 15px 0px 0px 15px; }
.brsr15 { border-radius: 0px 15px 15px 0px; }
.brsb15 { border-radius: 0px 0px 15px 15px; }
.brs999 { border-radius: 999px; }
.brst999 { border-radius: 999px 999px 0px 0px; }
.brsl999 { border-radius: 999px 0px 0px 999px; }
.brsr999 { border-radius: 0px 999px 999px 0px; }
.brsb999 { border-radius: 0px 0px 999px 999px; }
.brs50p { border-radius: 50%; }


/* Display */
/* ======= */
.dn { display: none; }
.dn_i { display: none !important; }
.db { display: block; }
.dib { display: inline-block; }
.dt { display: table; }
.dtr { display: table-row; }
.dtc { display: table-cell; }

/* Flex */
.dfdr { display: flex; flex-direction: row; }
.dfdrr { display: flex; flex-direction: row-reverse; }
.dfdc { display: flex; flex-direction: column; }
.dfdcr { display: flex; flex-direction: column-reverse; }
.difdr { display: inline-flex; flex-direction: row; }
.difdrr { display: inline-flex; flex-direction: row-reverse; }
.difdc { display: inline-flex; flex-direction: column; }
.difdcr { display: inline-flex; flex-direction: column-reverse; }
.fwn { flex-wrap: nowrap; }
.fww { flex-wrap: wrap; }
.fwwr { flex-wrap: wrap-reverse; }
.fjcs { justify-content: flex-start; }
.fjce { justify-content: flex-end; }
.fjcc { justify-content: center; }
.fjcsa { justify-content: space-around; }
.fjcsb { justify-content: space-between; }
.fais { align-items: flex-start; }
.faie { align-items: flex-end; }
.faic { align-items: center; }
.faib { align-items: baseline; }
.faist { align-items: stretch; }
.facs { align-content: flex-start; }
.face { align-content: flex-end; }
.facc { align-content: center; }
.facsa { align-content: space-around; }
.facsb { align-content: space-between; }
.facst { align-content: stretch; }
.fass { align-self: flex-start; }
.fase { align-self: flex-end; }
.fasc { align-self: center; }
.fasb { align-self: baseline; }
.fasst { align-self: stretch; }


/* Cursor */
/* ====== */
.curp, .curp_a, .curp_a * { cursor: pointer; }
.curp_i, .curp_a_i, .curp_a_i * { cursor: pointer !important; }
.curd, .curd_a, .curd_a * { cursor: default; }
.curd_i, .curd_a_i, .curd_a_i * { cursor: default !important; }


/* Height */
/* ====== */
.h0 { height: 0; }
.h100p, .hfull { height: 100%; }
.hauto { height: auto; }


/* Width */
/* ===== */
.w0 { width: 0; }
.w1 { width: 1px; }
.w5p { width: 5%; }
.w7p { width: 7%; }
.w10p { width: 10%; }
.w12p { width: 12.5%; }
.w15p { width: 15%; }
.w20p { width: 20%; }
.w25p { width: 25%; }
.w33p { width: 33.333333%; }
.w35p { width: 35%; }
.w40p { width: 40%; }
.w50p { width: 50%; }
.w60p { width: 60%; }
.w66p { width: 66.666666%; }
.w70p { width: 70%; }
.w75p { width: 75%; }
.w80p { width: 80%; }
.w85p { width: 85%; }
.w88p { width: 87.5%; }
.w90p { width: 90%; }
.w93p { width: 93%; }
.w95p { width: 95%; }
.w100p, .wfull { width: 100%; }
.wauto { width: auto; }


/* Margins */
/* ======= */
.m0auto, .m0a { margin: 0 auto; }
.m0auto_i, .m0a_i { margin: 0 auto !important; }
.mauto0, .ma0 { margin: auto 0; }
.mauto0_i, .ma0_i { margin: auto 0 !important; }
.mauto, .ma { margin: auto; }
.mauto_i, .ma_i { margin: auto !important; }
.mtauto, .mta { margin-top: auto; }
.mtauto_i, .mta_i { margin-top: auto !important; }
.mlauto, .mla { margin-left: auto; }
.mlauto_i, .mla_i { margin-left: auto !important; }
.mrauto, .mra { margin-right: auto; }
.mrauto_i, .mra_i { margin-right: auto !important; }
.mbauto, .mba { margin-bottom: auto; }
.mbauto_i, .mba_i { margin-bottom: auto !important; }
.m0 { margin: 0; }
.m0_i { margin: 0 !important; }
.mt0 { margin-top: 0; }
.mt0_i { margin-top: 0 !important; }
.ml0 { margin-left: 0; }
.ml0_i { margin-left: 0 !important; }
.mr0 { margin-right: 0; }
.mr0_i { margin-right: 0 !important; }
.mb0 { margin-bottom: 0; }
.mb0_i { margin-bottom: 0 !important; }
.m1 { margin: 1px; }
.mt1 { margin-top: 1px; }
.ml1 { margin-left: 1px; }
.mr1 { margin-right: 1px; }
.mb1 { margin-bottom: 1px; }
.m2 { margin: 2px; }
.mt2 { margin-top: 2px; }
.ml2 { margin-left: 2px; }
.mr2 { margin-right: 2px; }
.mb2 { margin-bottom: 2px; }
.m3 { margin: 3px; }
.mt3 { margin-top: 3px; }
.ml3 { margin-left: 3px; }
.mr3 { margin-right: 3px; }
.mb3 { margin-bottom: 3px; }
.m4 { margin: 4px; }
.mt4 { margin-top: 4px; }
.ml4 { margin-left: 4px; }
.mr4 { margin-right: 4px; }
.mb4 { margin-bottom: 4px; }
.m5 { margin: 5px; }
.mt5 { margin-top: 5px; }
.ml5 { margin-left: 5px; }
.mr5 { margin-right: 5px; }
.mb5 { margin-bottom: 5px; }
.m7 { margin: 7px; }
.mt7 { margin-top: 7px; }
.ml7 { margin-left: 7px; }
.mr7 { margin-right: 7px; }
.mb7 { margin-bottom: 7px; }
.m10 { margin: 10px; }
.mt10 { margin-top: 10px; }
.ml10 { margin-left: 10px; }
.mr10 { margin-right: 10px; }
.mb10 { margin-bottom: 10px; }
.m13 { margin: 13px; }
.mt13 { margin-top: 13px; }
.ml13 { margin-left: 13px; }
.mr13 { margin-right: 13px; }
.mb13 { margin-bottom: 13px; }
.m15 { margin: 15px; }
.mt15 { margin-top: 15px; }
.ml15 { margin-left: 15px; }
.mr15 { margin-right: 15px; }
.mb15 { margin-bottom: 15px; }
.m20 { margin: 20px; }
.mt20 { margin-top: 20px; }
.ml20 { margin-left: 20px; }
.mr20 { margin-right: 20px; }
.mb20 { margin-bottom: 20px; }
.m25 { margin: 25px; }
.mt25 { margin-top: 25px; }
.ml25 { margin-left: 25px; }
.mr25 { margin-right: 25px; }
.mb25 { margin-bottom: 25px; }
.m30 { margin: 30px; }
.mt30 { margin-top: 30px; }
.ml30 { margin-left: 30px; }
.mr30 { margin-right: 30px; }
.mb30 { margin-bottom: 30px; }
.m35 { margin: 35px; }
.mt35 { margin-top: 35px; }
.ml35 { margin-left: 35px; }
.mr35 { margin-right: 35px; }
.mb35 { margin-bottom: 35px; }
.m-1 { margin: -1px; }
.mt-1 { margin-top: -1px; }
.ml-1 { margin-left: -1px; }
.mr-1 { margin-right: -1px; }
.mb-1 { margin-bottom: -1px; }
.m-2 { margin: -2px; }
.mt-2 { margin-top: -2px; }
.ml-2 { margin-left: -2px; }
.mr-2 { margin-right: -2px; }
.mb-2 { margin-bottom: -2px; }
.m-3 { margin: -3px; }
.mt-3 { margin-top: -3px; }
.ml-3 { margin-left: -3px; }
.mr-3 { margin-right: -3px; }
.mb-3 { margin-bottom: -3px; }
.m-4 { margin: -4px; }
.mt-4 { margin-top: -4px; }
.ml-4 { margin-left: -4px; }
.mr-4 { margin-right: -4px; }
.mb-4 { margin-bottom: -4px; }
.m-5 { margin: -5px; }
.mt-5 { margin-top: -5px; }
.ml-5 { margin-left: -5px; }
.mr-5 { margin-right: -5px; }
.mb-5 { margin-bottom: -5px; }
.m-7 { margin: -7px; }
.mt-7 { margin-top: -7px; }
.ml-7 { margin-left: -7px; }
.mr-7 { margin-right: -7px; }
.mb-7 { margin-bottom: -7px; }
.m-10 { margin: -10px; }
.mt-10 { margin-top: -10px; }
.ml-10 { margin-left: -10px; }
.mr-10 { margin-right: -10px; }
.mb-10 { margin-bottom: -10px; }
.m-13 { margin: -13px; }
.mt-13 { margin-top: -13px; }
.ml-13 { margin-left: -13px; }
.mr-13 { margin-right: -13px; }
.mb-13 { margin-bottom: -13px; }
.m-15 { margin: -15px; }
.mt-15 { margin-top: -15px; }
.ml-15 { margin-left: -15px; }
.mr-15 { margin-right: -15px; }
.mb-15 { margin-bottom: -15px; }
.m-20 { margin: -20px; }
.mt-20 { margin-top: -20px; }
.ml-20 { margin-left: -20px; }
.mr-20 { margin-right: -20px; }
.mb-20 { margin-bottom: -20px; }
.m-25 { margin: -25px; }
.mt-25 { margin-top: -25px; }
.ml-25 { margin-left: -25px; }
.mr-25 { margin-right: -25px; }
.mb-25 { margin-bottom: -25px; }
.m-30 { margin: -30px; }
.mt-30 { margin-top: -30px; }
.ml-30 { margin-left: -30px; }
.mr-30 { margin-right: -30px; }
.mb-30 { margin-bottom: -30px; }
.m-35 { margin: -35px; }
.mt-35 { margin-top: -35px; }
.ml-35 { margin-left: -35px; }
.mr-35 { margin-right: -35px; }
.mb-35 { margin-bottom: -35px; }


/* Paddings */
/* ======== */
.p0 { padding: 0 !important; }
.pt0 { padding-top: 0 !important; }
.pl0 { padding-left: 0 !important; }
.pr0 { padding-right: 0 !important; }
.pb0 { padding-bottom: 0 !important; }
.p1 { padding: 1px !important; }
.pt1 { padding-top: 1px !important; }
.pl1 { padding-left: 1px !important; }
.pr1 { padding-right: 1px !important; }
.pb1 { padding-bottom: 1px !important; }
.p2 { padding: 2px !important; }
.pt2 { padding-top: 2px !important; }
.pl2 { padding-left: 2px !important; }
.pr2 { padding-right: 2px !important; }
.pb2 { padding-bottom: 2px !important; }
.p3 { padding: 3px !important; }
.pt3 { padding-top: 3px !important; }
.pl3 { padding-left: 3px !important; }
.pr3 { padding-right: 3px !important; }
.pb3 { padding-bottom: 3px !important; }
.p4 { padding: 4px !important; }
.pt4 { padding-top: 4px !important; }
.pl4 { padding-left: 4px !important; }
.pr4 { padding-right: 4px !important; }
.pb4 { padding-bottom: 4px !important; }
.p5 { padding: 5px !important; }
.pt5 { padding-top: 5px !important; }
.pl5 { padding-left: 5px !important; }
.pr5 { padding-right: 5px !important; }
.pb5 { padding-bottom: 5px !important; }
.p7 { padding: 7px !important; }
.pt7 { padding-top: 7px !important; }
.pl7 { padding-left: 7px !important; }
.pr7 { padding-right: 7px !important; }
.pb7 { padding-bottom: 7px !important; }
.p10 { padding: 10px !important; }
.pt10 { padding-top: 10px !important; }
.pl10 { padding-left: 10px !important; }
.pr10 { padding-right: 10px !important; }
.pb10 { padding-bottom: 10px !important; }
.p13 { padding: 13px !important; }
.pt13 { padding-top: 13px !important; }
.pl13 { padding-left: 13px !important; }
.pr13 { padding-right: 13px !important; }
.pb13 { padding-bottom: 13px !important; }
.p15 { padding: 15px !important; }
.pt15 { padding-top: 15px !important; }
.pl15 { padding-left: 15px !important; }
.pr15 { padding-right: 15px !important; }
.pb15 { padding-bottom: 15px !important; }
.p20 { padding: 20px !important; }
.pt20 { padding-top: 20px !important; }
.pl20 { padding-left: 20px !important; }
.pr20 { padding-right: 20px !important; }
.pb20 { padding-bottom: 20px !important; }
.p25 { padding: 25px !important; }
.pt25 { padding-top: 25px !important; }
.pl25 { padding-left: 25px !important; }
.pr25 { padding-right: 25px !important; }
.pb25 { padding-bottom: 25px !important; }
.p30 { padding: 30px !important; }
.pt30 { padding-top: 30px !important; }
.pl30 { padding-left: 30px !important; }
.pr30 { padding-right: 30px !important; }
.pb30 { padding-bottom: 30px !important; }
.p35 { padding: 35px !important; }
.pt35 { padding-top: 35px !important; }
.pl35 { padding-left: 35px !important; }
.pr35 { padding-right: 35px !important; }
.pb35 { padding-bottom: 35px !important; }

.pt10p { padding-top: 10% !important; }
.pt15p { padding-top: 15% !important; }
.pt20p { padding-top: 20% !important; }
.pt25p { padding-top: 25% !important; }
.pt33p { padding-top: 33.333333% !important; }
.pt35p { padding-top: 35% !important; }
.pt40p { padding-top: 40% !important; }
.pt50p { padding-top: 50% !important; }
.pt60p { padding-top: 60% !important; }
.pt66p { padding-top: 66.666666% !important; }
.pt70p { padding-top: 70% !important; }
.pt75p { padding-top: 75% !important; }
.pt80p { padding-top: 80% !important; }
.pt85p { padding-top: 85% !important; }
.pt90p { padding-top: 90% !important; }
.pt100p { padding-top: 100% !important; }


/* Aligning */
/* ======== */
/* Vertical align */
.vat { vertical-align: top; }
.vam { vertical-align: middle; }
.vab { vertical-align: bottom; }
.vabl { vertical-align: baseline; }

/* Horizontal text align */
.tal { text-align: left; }
.tal_i { text-align: left !important; }
.tac { text-align: center; }
.tac_i { text-align: center !important; }
.tar { text-align: right; }
.tar_i { text-align: right !important; }
.taj { text-align: justify; }
.taj_i { text-align: justify !important; }


/* Text styling */
/* ============ */
/* Font style*/
.fsi { font-style: italic; }

/* Font size */
.fz0 { font-size: 0; }
.fz10 { font-size: 10px; }
.fz11 { font-size: 11px; }
.fz12 { font-size: 12px; }
.fz13 { font-size: 13px; }
.fz14 { font-size: 14px; }
.fz15 { font-size: 15px; }
.fz16 { font-size: 16px; }
.fz18 { font-size: 18px; }
.fz20 { font-size: 20px; }
.fz22 { font-size: 22px; }
.fz24 { font-size: 24px; }
.fz26 { font-size: 26px; }
.fz28 { font-size: 28px; }
.fz30 { font-size: 30px; }
.fz32 { font-size: 32px; }
.fz34 { font-size: 34px; }
.fz36 { font-size: 36px; }
.fz38 { font-size: 38px; }
.fz40 { font-size: 40px; }
.fz42 { font-size: 42px; }
.fz44 { font-size: 44px; }
.fz46 { font-size: 46px; }
.fz48 { font-size: 48px; }
.fz50 { font-size: 50px; }
.fz52 { font-size: 52px; }
.fz54 { font-size: 54px; }
.fz56 { font-size: 56px; }
.fz58 { font-size: 58px; }
.fz60 { font-size: 60px; }
.fz0_i { font-size: 0 !important; }
.fz10_i { font-size: 10px !important; }
.fz11_i { font-size: 11px !important; }
.fz12_i { font-size: 12px !important; }
.fz13_i { font-size: 13px !important; }
.fz14_i { font-size: 14px !important; }
.fz15_i { font-size: 15px !important; }
.fz16_i { font-size: 16px !important; }
.fz18_i { font-size: 18px !important; }
.fz20_i { font-size: 20px !important; }
.fz22_i { font-size: 22px !important; }
.fz24_i { font-size: 24px !important; }
.fz26_i { font-size: 26px !important; }
.fz28_i { font-size: 28px !important; }
.fz30_i { font-size: 30px !important; }
.fz32_i { font-size: 32px !important; }
.fz34_i { font-size: 34px !important; }
.fz36_i { font-size: 36px !important; }
.fz38_i { font-size: 38px !important; }
.fz40_i { font-size: 40px !important; }
.fz42_i { font-size: 42px !important; }
.fz44_i { font-size: 44px !important; }
.fz46_i { font-size: 46px !important; }
.fz48_i { font-size: 48px !important; }
.fz50_i { font-size: 50px !important; }
.fz52_i { font-size: 52px !important; }
.fz54_i { font-size: 54px !important; }
.fz56_i { font-size: 56px !important; }
.fz58_i { font-size: 58px !important; }
.fz60_i { font-size: 60px !important; }

/* Font weight */
.fw900, .fw900_a, .fw900_a * { font-weight: 900; }
.fw900_i, .fw900_a_i, .fw900_a_i * { font-weight: 900 !important; }
.fw800, .fw800_a, .fw800_a * { font-weight: 800; }
.fw800_i, .fw800_a_i, .fw800_a_i * { font-weight: 800 !important; }
.fw700, .fw700_a, .fw700_a *, .bold, .bold_a, .bold_a * { font-weight: 700; }
.fw700_i, .fw700_a_i, .fw700_a_i *, .bold_i, .bold_a_i, .bold_a_i * { font-weight: 700 !important; }
.fw600, .fw600_a, .fw600_a * { font-weight: 600; }
.fw600_i, .fw600_a_i, .fw600_a_i * { font-weight: 600 !important; }
.fw500, .fw500_a, .fw500_a * { font-weight: 500; }
.fw500_i, .fw500_a_i, .fw500_a_i * { font-weight: 500 !important; }
.fw400, .fw400_a, .fw400_a * { font-weight: 400; }
.fw400_i, .fw400_a_i, .fw400_a_i * { font-weight: 400 !important; }
.fw300, .fw300_a, .fw300_a *, .semilight, .semilight_a, .semilight_a * { font-weight: 300; }
.fw300_i, .fw300_a_i, .fw300_a_i *, .semilight_i, .semilight_a_i, .semilight_a_i * { font-weight: 300 !important; }

/* Font inheritting */
.inherit { font-family: inherit; font-size: inherit; font-weight: inherit; font-style: inherit; text-transform: inherit; }
.fzinherit { font-size: inherit; }

/* Text uppercase */
.caps { text-transform: uppercase; }

/* Text decoration*/
.tdn, .tdn_a, .tdn_a *, .tdn_h:hover, .tdn_a_h:hover, .tdn_a_h:hover * { text-decoration: none; }
.tdn_i, .tdn_a_i, .tdn_a_i *, .tdn_h_i:hover, .tdn_a_h_i:hover, .tdn_a_h_i:hover * { text-decoration: none !important; }
.tdu, .tdu_a, .tdu_a *, .tdu_h:hover, .tdu_a_h:hover, .tdu_a_h:hover * { text-decoration: underline; }
.tdu_i, .tdu_a_i, .tdu_a_i *, .tdu_h_i:hover, .tdu_a_h_i:hover, .tdu_a_h_i:hover * { text-decoration: underline !important; }
.tdlth, .tdlth_a, .tdlth_a *, .tdlth_h:hover, .tdlth_a_h:hover, .tdlth_a_h:hover * { text-decoration: line-through; }
.tdlth_i, .tdlth_a_i, .tdlth_a_i *, .tdlth_h_i:hover, .tdlth_a_h_i:hover, .tdlth_a_h_i:hover * { text-decoration: line-through !important; }

/* White space */
.wsn { white-space: normal; }
.wsnw { white-space: nowrap; }
.wsp { white-space: pre; }
.wspl { white-space: pre-line; }
.wspw { white-space: pre-wrap; }


/* Overflow */
/* ======== */
.ofh { overflow: hidden; }


/* Positioning */
/* =========== */
.posr { position: relative; }
.posa { position: absolute; }
.posf { position: fixed; }


/* Z-axis positioning */
/* ================== */
.z0 { z-index: 0; }
.z1 { z-index: 1; }
.z4 { z-index: 4; }
.z9 { z-index: 9; }
.z49 { z-index: 49; }
.z99 { z-index: 99; }
.z999 { z-index: 999; }
.z9999 { z-index: 9999; }


/* Element position */
/* ================ */
.t0 { top: 0; }
.t100p { top: 100%; }
.t-100p { top: -100%; }
.l0 { left: 0; }
.l100p { left: 100%; }
.l-100p { left: -100%; }
.r0 { right: 0; }
.r100p { right: 100%; }
.r-100p { right: -100%; }
.b0 { bottom: 0; }
.b100p { bottom: 100%; }
.b-100p { bottom: -100%; }


/* Floating */
/* ======== */
.fll { float: left; }
.flr { float: right; }
